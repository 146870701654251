.cover-full{ /* object absolute cover full area */
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

}

.projects .box-image{
    height: 280px;
    margin: 0 auto 30px;
}
.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.desc h4,
.desc a,
.desc a:hover{
    color: #fff;
}
.desc h4 {
    font-weight: 700;
    margin-bottom:30px;
}
.desc a {
    border:1px solid #fff;
    padding:10px;
    border-radius:5px;
    font-size:.9em;
}
.box-image:hover .image {
    transform: scale(1.1);
}
.box-image:hover .shadow {
    opacity: 0.1;
}
.box-image:hover .desc *{
    text-shadow: 1px 1px 10px #080808;
}
.transition{ /* transition for diferents class */
    -webkit-transition:all 200ms linear;
	-moz-transition:all 200ms linear;
	-o-transition:all 200ms linear;
	-ms-transition:all 200ms linear;
	transition:all 200ms linear;
}

.box-image{
    position: relative;
    overflow: hidden;
}
.box-image .image {
    background-size: cover;
    background-position: center center;
}
.box-image .shadow{
    background-color:#000;
    opacity: .8;
}
.box-image .sale{
    position: relative;
    background-color: red;
    color: white;
    font-weight: 700;
    padding:3%;
    max-height: 100px;
    top: 200px;
    width: 68%;
    height: 50px;
    transform: rotate(45deg);
    text-align: center;
    right: 56px;
    font-size: 11px;
    padding-top:15px;

    @media only screen and (min-width:768px) and (max-width:1000px){
        width: 96%;
    }
}

.box-image .desc{
    text-align: center;
}
