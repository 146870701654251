.member{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    padding-bottom: 30px;
    width:100%;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 0rem !important;
     }
    @media (min-width: 1024px) {
      gap: 2.5rem;
     }
    }
    .pt-5{
      padding-top:4rem !important;
    }
    .h2{
    margin-top: 5.25rem;
    font-size: 2.375rem;
    line-height: 2.5rem;
    font-weight: 700;
    }
    .p{
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    }
    .memberinfo{
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .email{
        position: absolute;
        bottom: 0px;
        @media (max-width:990px){
          position: relative;
        }
        @media (max-width:770px){
          position: relative;
        }
    }
    .ul {
    display: flex;
    justify-content: space-between;
    align-items: center;

    }

    .link{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    border-width: 2px;
    border-color: #000000;
    font-weight:bolder;

    }
    .button{
        padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    }
    .schedule{
      color: white;
    }

    .teamimage{
      margin-top: 48px;
        object-fit: cover;
        max-width: 70%;
        border-radius: 40px;
        height:600px;
        @media (max-width:990px){
          height:fit-content;
          width: 440px;
        }
        @media (max-width:770px){
          height:fit-content;
          width: 440px;
        }
    }

    .sectionsingle{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        @media (min-width: 1024px) {
          padding-top: 5rem;
          padding-bottom: 5rem;
         }
        @media (min-width: 1280px) {
          max-width: 72rem;
         }


    }
    .h1{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;


    @media (min-width: 768px) {
      font-size: 3rem;
    line-height: 1;
    // display: inline;
    }
    @media (min-width: 1024px) {
      margin-bottom: 2.5rem;
    font-size: 3.75rem;
    line-height: 1;

     }
    }

    .h11{
      margin-bottom: 2.5rem;
    font-size: 2.25rem;
    line-height: 1;
    }

    .ulsingle{
    display: flex;
    margin-top: 2.5rem;
    align-items: center;

    }

    .main{
      padding-top: 40px;
      padding-bottom: 40px;
      font-size: 20px;
      font-weight: lighter;
      @media (max-width: 768px) {
        font-size: 17px;
      // display: inline;
      }
    }

    .process{
      font-weight: bold;
    }

    .bottom{
      display: flex;
      flex-direction: row;
    }

    .svg{
      margin-left:5%;
    }

    ::first-letter {
      text-transform:none;
  }

  .team {
    display: flex;
  }

  @media (max-width: 1000px) {
    .team {
      display: flex;
      flex-direction: column;
    }
  }