.site-header {
	position: absolute !important;
	top: 0;
	width: 100%;
	z-index: 10 !important;
	background: color-bg(header);

	+ .site-content {

		.section:first-of-type {
			padding-top: $header-height__mobile;
		}
	}

	.brand {
		margin-right: $header-nav--padding-h;
	}
}

.site-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
	height: $header-height__mobile;
	margin-top: 8px;
}

.header-nav {
	flex-grow: 1;

	.header-nav-inner {
		display: flex;
		flex-grow: 1;
	}

    ul {
		margin-top: 10px;
        display: flex;
        align-items: center;
		flex-grow: 1;
        white-space: nowrap;
		margin-bottom: 0;

		&:first-of-type {
			flex-wrap: wrap;
		}
    }

    li {

		+ .header-button {
			margin-left: $header-nav--padding-h;
		}
    }

    a:not(.button) {
		display: block;
        @include anchor-aspect(header);
		@include font-weight(header-link);
		text-transform: $link-header--transform;
        padding: 0 $header-nav--padding-h;

		.invert-color & {
			@include anchor-aspect(header, inverse);
		}
    }

	a.button {
		margin-left: $header-nav--padding-h;
	}

}
.noti{
	color: white;
	text-decoration: none;
	position: relative;
	display: inline-block;
	border-radius: 2px;
}
.noti .badge{
  position: absolute;
  top: -12px;
  left: 88px;
  border-radius: 50%;
  color: white;
}
.header-nav-center:first-of-type {
	flex-grow: 1;
	justify-content: flex-end;
}

.header-nav-right {
	justify-content: flex-end;

	+ .header-nav-right {
		flex-grow: 0;
	}
}

.header-nav-toggle {
	display: none;
}

@include media( '<=medium' ) {

	.header-nav-toggle {
		display: block;

		// Header navigation when the hamburger is a previous sibling
		+ .header-nav {
			flex-direction: column;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 9999;
			background: color-bg(menu-mobile);
			// max-height: 0;
	        opacity: 0;
	        overflow: hidden;
	        transition: max-height .25s ease-in-out, opacity .15s;
			display: none;
			&.is-active {
				opacity: 1;
			display: block;

			}

			.header-nav-inner {
				flex-direction: column;
				padding: $header-nav--padding-v__mobile;
				height: 400px;
			}

			ul {
				display: block;
				text-align: center;

			    a:not(.button) {
					display: inline-flex;
			        @include anchor-aspect(header-mobile);
					padding-top: $header-nav--padding-v__mobile * 0.5;
					padding-bottom: $header-nav--padding-v__mobile * 0.5;
			    }
			}

			a.button {
				margin-left: 0;
				margin-top: $header-nav--padding-v__mobile * 0.5;
				margin-bottom: $header-nav--padding-v__mobile * 0.5;
			}
		}
	}
}

@include media( '>medium' ) {

	.site-header {

		+ .site-content {

			.section:first-of-type {
				padding-top: $header-height__desktop;
			}
		}
	}

	.site-header-inner {
		height: $header-height__desktop;
	}
}

.dropbtn {
	background-color:transparent;
	color: #9ca9b3;
	// padding: 16px;
	border: none;
  }

  .dropdown {
	position: relative;
	display: inline-block;
  }

  .dropbtn a{
	display: flex !important;
  }
  .dropbtn img{
	margin-left: 10px;
  }

  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #2b2a29;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
  }

  .dropdown-content a {
	color: black;
	padding: 12px 16px !important;
	text-decoration: none;
	display: flex !important;
	cursor: pointer;
  }
  .dropdown-content img{
	margin-left: 10px;
  }
  .dropdown-content a:hover {background-color: #CFB261;}
  .dropdown:hover .dropdown-content {display: block;}


  .down {
	border: solid #9ca9b3;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
  }