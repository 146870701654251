.timeline {
  position: relative !important;
  padding: 0 !important;
  list-style: none !important;
}
.img-fluid {
  margin-top: 3%;
  height: 92%;
  width: 96%;
  max-width: 100% !important;
}
// .rounded-circle {
//   border-radius: 50% !important;
// }
.specialPanel{
  float:right;
  width: 41%;
  padding: 0 20px 20px 30px;
  text-align: left;
  @media(max-width:767px){
  width: 100%;
  padding: 0 20px 0 100px;

  }
}
.lastPanel{
  margin-top: 150px;
  padding: 0 20px 0 30px;
  @media(max-width:767px){
    margin-top: 0px;
    padding: 0 20px 0 100px;
    .text-center{
      text-align: start !important;
    }
  }
}
.panels{
  margin-top: 100px;
  @media(max-width:767px){
    margin-top: 20px;
  }
}
.text-muted{
  color: white  !important;
}
img,
svg {
  vertical-align: middle !important;
}
.timeline:before {
  height: 84%;
  @media(max-width:611px){
    height: 77%;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}
.timeline > li:after, .timeline > li:before {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li .timeline-image {
  position: absolute;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #cfb261;
  border-radius: 100%;
  background-color: #cfb261;
}
.timeline > li .text-muted{
  font-size: 15px;
}
.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}
.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}
@media(min-width:768px){
  .timeline:before {
    left:48%;
    @media(max-width:992px){
      left: 49%;
    }
  }
  .timeline > li {
    min-height: 100px;
    // margin-bottom: 70px !important;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 49%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 110px;
    height: 110px;
    margin-left: -70px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline:before {
    left: 48%;
  }
  .timeline > li {
    min-height: 170px;
  }
  // .timeline > li .timeline-panel {
  //   padding: 0 20px 20px 100px;
  // }
  .timeline > li .timeline-image {
    width: 120px;
    height: 120px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    margin-top: 30px;
  }
  // .timeline > li.timeline-inverted > .timeline-panel {
  //   padding: 0 100px 20px 20px;
  // }
}