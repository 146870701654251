#contact
{
position: relative;
padding: 50px 0;
/* background: url(./travel_photos/co.jpg); */
background-size: cover;
min-height: 60vh;
background-color: #CFB261;
}
#contact p{
    color: black;
}
#contact h2{
    color: black;
}
#contact form
{
position: relative;
z-index: 1000;
}
#contact .form-control
{
border: none;
border-bottom: 2px solid black;
height: 50px;
border-radius: 0;
color: #fff;
font-size: 1rem;
background-color: #CFB261;
width:70%;
@media only screen and (max-width: 766px) {
  width:80%;
}
}
#contact .form-textarea{
display: block;
border: none;
border-bottom: 2px solid black;
height: 50px;
border-radius: 0;
color: #fff;
font-size: 1.25rem;
background-color: #CFB261;
width:90%;
  @media only screen and (max-width: 766px) {
  height: 100px;
}
}
#contact .form-control:focus,
#contact .form-textarea:focus,
#contact .form-textarea:hover,
#contact .form-control:hover
{
outline: none;
border: none;
border-bottom: 2px solid rgba(255,255,255,1);
box-shadow: none;
}
#contact .textarea
{
min-height: 100px;
}
#contact label
{
color: black;
font-size: 1.0rem;
}
.foot
{
  font-size:14px;
  padding:10px;
  margin:0px;
  background-color:black ;
}
.recent-post
{
  list-style: none;
}
span .cal
{
  padding:9px;
  background-color: #000000;

}
span .cal .date
{
  font-size:25px;
}
span .cal .month
{
  font-size:12px;
  /* font-weight: bold; */
  color:white;
}
.title
{
  padding:10px;
  color:white;
  font-size:14px;
}
.subtitle
{
  color:gray;
  font-size:12px;
}
.ic
{
  padding:14px;
  color:#CFB261;
}
.borbox .topic
{
  font-size: 17px;
  color:white;
  padding:1px;
}
.subtopic
{
  font-size: 14px;
  color:gray;
}
.borbox
{
  padding-left:10px;
  margin-top:20px;
  margin-left:80px;
  border:1px solid gray;
  width:200px;
}
@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
  .site-footer{
    padding:30px;
  }
  .borbox{
    padding:1px;
    margin:5px;
    width:100%;
  }
}
.btnD2{
background: #262626;
color: white;
border-radius: 5px;
border-color:#262626 ;
padding: 10px 20px;
float:left;
margin-top:15px;
}
.btnD2:disabled{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor:not-allowed !important;

}
.recaptcha{
  margin-top: 10px;
}

.rightcontainer{
  padding-top: 3%;
  @media only screen and (max-width: 767px) {
   margin-left: 15px;
  }
}


table{
  width:10% !important
}

tr{
  border-bottom: 0px !important;
}

td{
  color:#000000
}

table{
  display: none !important;
}

p:hover + table{
  display: block !important;
}

th,td{
  padding :5px 7px !important
}

.whatsappcontainer{
  line-height: 57px;
  background-color: #262626;
  width:75%;
  border: none;
  border-radius: 10cm;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  @media only screen and (max-width: 930px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 370px) {
    font-size: 11px;
  }
}

