.testimonial {

	.section-inner {
		padding-top: $testimonial--padding-t__mobile;
		padding-bottom: $testimonial--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $testimonial-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $testimonial-items--padding__mobile != null ) {
			margin-right: -($testimonial-items--padding__mobile * 0.5);
			margin-left: -($testimonial-items--padding__mobile * 0.5);
			margin-top: -($testimonial-items--padding__mobile * 0.5);

			&:last-of-type {
				margin-bottom: -($testimonial-items--padding__mobile * 0.5);
			}

			&:not(:last-of-type) {
				margin-bottom: ($testimonial-items--padding__mobile * 0.5);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $testimonial-item--width;
				max-width: $testimonial-item--width;
				@if ( $testimonial-items--padding__mobile != null ) {
					padding-left: $testimonial-items--padding__mobile * 0.5;
					padding-right: $testimonial-items--padding__mobile * 0.5;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $testimonial-item--width;
		max-width: $testimonial-item--width;
		@if ( $testimonial-items--padding__mobile != null ) {
			padding: $testimonial-items--padding__mobile * 0.5;
		}
	}

	.tiles-item-inner {
		padding-top: $testimonial-item--inner-padding-v;
		padding-bottom: $testimonial-item--inner-padding-v;
		padding-left: $testimonial-item--inner-padding-h;
		padding-right: $testimonial-item--inner-padding-h;
	}
	// .single__member-social {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	column-gap: 0.5rem;
	// 	position: absolute;
	// 	top: -100%;
	// 	left: 50%;
	// 	width: 100%;
	// 	height: 100%;
	// 	background: rgba(207, 178, 97, 0.7);
	// 	transform: translateX(-50%);
	// 	border-radius: 4px;
	// 	transition: 0.5s ease;
	// 	opacity: 0;
	//   }

	// .tiles-item-inner:hover .single__member-social {
	// 	top: 0;
	// 	opacity: 1;
	//   }
}

.testimonial-item-content {
	flex-grow: 1;
}

.testimonial-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

@include media( '>medium' ) {

	.testimonial {

		.section-inner {
			padding-top: $testimonial--padding-t__desktop;
			padding-bottom: $testimonial--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $testimonial-header--padding__desktop;
		}

		@if ( $testimonial-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($testimonial-items--padding__desktop * 0.5);
				margin-left: -($testimonial-items--padding__desktop * 0.5);
				margin-top: -($testimonial-items--padding__desktop * 0.5);

				&:last-of-type {
					margin-bottom: -($testimonial-items--padding__desktop * 0.5);
				}

				&:not(:last-of-type) {
					margin-bottom: ($testimonial-items--padding__desktop * 0.5);
				}

				&.push-left {

					&::after {
						padding-left: $testimonial-items--padding__desktop * 0.5;
						padding-right: $testimonial-items--padding__desktop * 0.5;
					}
				}
			}

			.tiles-item {
				padding: $testimonial-items--padding__desktop * 0.5;
			}
		}
	}
}

.respo-text{
	font-size: 15px;


	@media(max-width:379px){
		font-size: 9px ;
	}

	@media (min-width:380px) and (max-width: 1440px) {
		font-size: 10px;
	}
}


  .slick-slide {
	padding-left: 10px;
	height:auto;
  }

