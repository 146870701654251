.position{
    position: fixed;
    right: 0.5rem;
    bottom: 0.5rem;
    z-index: 1;
    }

    .position2{
    display: inline-flex;
    padding: 0.75rem;
    background-color: #CFB261;
    transition-property: opacity;
    color: #ffffff;
    align-items: center;
    cursor: pointer;
    border: none;
    border-radius: 9999px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }
    .op1{
        opacity: 1;
    }
    .op2{
        opacity: 0;
    }

    .scrollbutton{
        width: 1.5rem;
    height: 1.5rem;

    }