
.alert-danger{
    --bs-alert-color: #fdfdfd !important;
    --bs-alert-bg: #f50000  !important;
    width: 16rem !important;
    margin-top: 14px !important;
    font-size: 16px;
}

::first-letter {
    text-transform:capitalize;
}