    .article{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.25rem;
    padding-bottom: 30px;

    @media (min-width: 1024px) {
      gap: 2.5rem;
     }
    }
    .h2{
    margin-top: 5.25rem;
    font-size: 2.375rem;
    line-height: 2.5rem;
    font-weight: 700;
    margin-bottom: 0px !important;
    }
    .p{
      font-size:25px;
      margin-top: 0px !important;
    }
    .desc{
      // font-weight: 700;
      font-size: 17px;
    }
    .ul {
    display: flex;
    justify-content: space-between;
    align-items: center;

    }

    .link{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    border-width: 2px;
    border-color: #000000;
    font-weight:bolder;

    }
    .butoni{
    padding-top: 1rem;
    padding-right: 1rem;
    @media (max-width:990px){
      padding-top: 3rem !important;
    }
    @media (max-width:770px){

    }
    }

    .pdffile{
      font-size: 20px !important;
      top: 0;
      display: inline-flex;
      color: black !important;
    }

.icon{
  margin: 0px 8px;
}

    .schedule{
      color: white;
    }


    .imgg{
        object-fit: cover;
        max-width: 100%;
        border-radius: 40px;
        @media (max-width:990px){
          height:370px;
        }
        @media (max-width:770px){
          height:370px;
          width: 440px;
        }
    }

    .sectionsingle{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        @media (min-width: 1024px) {
          padding-top: 5rem;
          padding-bottom: 5rem;
         }
        @media (min-width: 1280px) {
          max-width: 72rem;
         }


    }
    .h1{
        margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;


    @media (min-width: 768px) {
      font-size: 3rem;
    line-height: 1;
    // display: inline;
    }
    @media (min-width: 1024px) {
      margin-bottom: 2.5rem;
    font-size: 3.75rem;
    line-height: 1;

     }
    }

    .h11{
      margin-bottom: 2.5rem;
    font-size: 2.25rem;
    line-height: 1;
    }

    .ulsingle{
        display: flex;
    margin-top: 2.5rem;
    align-items: center;

    }

    .main{
      padding-top: 40px;
      padding-bottom: 40px;
      font-size: 20px;
      font-weight: lighter;
      @media (max-width: 768px) {
        font-size: 17px;
      // display: inline;
      }
    }

    .process{
      font-weight: bold;
    }

    .bottom{
      display: block !important;
    }

    .image-gallery-icon:hover{
      color: #CFB261 !important;
    }

    .image-gallery-thumbnail:hover{
      border:  4px solid #CFB261 !important;
    }
    .image-gallery-thumbnail.active{
      border: 4px solid #CFB261 !important;
    }

    .image-gallery-slide{
      left:0% !important;
      top:0% !important;
      width: 100% !important;

    }